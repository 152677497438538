<template>
  <div class="w-full h-96 relative">
    <div class="container flex justify-center items-center md:justify-start mx-auto h-full">
      <h3 class="text-white text-4xl font-bold col-span-3 mt-24 tracking-normal">{{title}}</h3>
    </div>
    <div class="absolute bg-text-color-dark right-0 top-0 w-full h-full z-[-1]">
      <img class="h-full w-full object-cover mix-blend-overlay" src="../../assets/images/banner.png" alt="banner">
    </div>
    <span class="event">{{eventoSeleccionado.nombre.substring(eventoSeleccionado.nombre.length - 4, eventoSeleccionado.nombre.length)}}</span>
  </div>
</template>

<script>
import {mapGetters} from "vuex"

  export default {
    props: {
      title:{
        type: String,
        default: 'Title'
      }
    },
    computed: {
      ...mapGetters(["eventoSeleccionado"])
    },
  }
</script>

<style scoped>

.event {
  word-wrap: break-word;
  width: 35px;
  line-height: 1.4;
  @apply absolute right-0 bottom-[60px] block font-bold text-white z-40 text-center text-2xl bg-theme-color px-2 py-2 rounded-bl-lg rounded-tl-lg;
}

</style>
