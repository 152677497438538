<template>
  <div>
    <cta-banner :title="$route.name"/>
    <section class="container mx-auto py-28">
      <div class="flex flex-col text-center w-full mb-10" v-if="tabla.length > 0">
        <h1 class="sm:text-4xl text-3xl font-medium title-font mb-2 text-theme-color tracking-tight">{{ header.titulo }}</h1>
        <p class="lg:w-2/3 mx-auto leading-relaxed text-base">{{ header.cuerpo }}</p>
      </div>
      <div class="lg:w-2/3 w-full mx-auto overflow-auto mb-20" v-if="tabla.length > 0">
      <table class="table-auto w-full text-left whitespace-no-wrap">
        <thead>
          <tr>
            <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100 rounded-tl rounded-bl">Categoría</th>
            <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">COP</th>
            <th class="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">USD</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in tabla" :key="item.id" v-html="item.cuerpo" />
        </tbody>
      </table>
    </div>
      <div class="w-full pb-16">
        <h2 class="text-3xl font-bold mb-7 title relative">{{ headerRegistro.titulo }}</h2>
        <p class="leading-9">{{ headerRegistro.cuerpo }} </p><br>
        <div v-for="item in tutorial" :key="tutorial.id" v-if="item.imagen">
          <h3 class="text-3xl font-bold mb-7 relative">{{ item.cuerpo }}</h3>
          <img class="py-5 w-full" :src="item.imagen" alt="">
        </div>

      </div>
      <div class="" v-if="formaPago.titulo">
        <div class="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
          <div class="flex items-center mb-3">
            <div class="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-theme-color text-white flex-shrink-0">
              <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
              </svg>
            </div>
            <h2 class="text-xl title font-medium tracking-tight text-theme-color">{{ formaPago.titulo }}</h2>
          </div>
          <div class="flex-grow">
            <p class="leading-relaxed text-base pl-10">{{ formaPago.cuerpo }}</p>
          </div>
        </div>
      </div>
      <div class="body-font" v-if="videos.length > 0">
        <div class="container px-5 pb-24 pt-10 mx-auto">
          <div class="flex flex-wrap -mx-4 -mb-10 text-center">
            <div class="sm:w-1/2 mb-10 px-4" v-for="video in videos" :key="`taller-${video.id}`">
              <div class="rounded-lg h-80 overflow-hidden">
                <iframe :src="`https://www.youtube.com/embed/${video.link}`" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen" loading="lazy" class="w-full h-full"></iframe>
              </div>
              <h2 class="title text-2xl font-medium mt-6 mb-1 tracking-tight relative">{{video.titulo}}</h2>
              <span class="inline-block h-1 w-10 rounded bg-theme-color my-1"></span>
              <p class="leading-relaxed" v-if="video.cuerpo">{{video.cuerpo}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full px-1" v-if="registroGeducar.titulo">
        <a :href="registroGeducar.link" target="_blank">
          <button class="text-white bg-theme-color py-5 px-7 rounded-md mt-8">{{ registroGeducar.titulo }}</button>
        </a>
      </div>
    </section>
  </div>
</template>

<script>
import CtaBanner from '../../components/common/CtaBanner.vue';
import API from "@/api";
import {mapMutations, mapState} from "vuex";

export default {
  name: "Informacion",
  components: {
    CtaBanner
  },
  data() {
    return {
      header: {},
      headerRegistro: {},
      formaPago: {},
      registroGeducar: {},
      tutorial: [],
      tabla: [],
      videos: []
    };
  },
  created() {
    this.getInfo();
  },
  computed: {
    ...mapState(["idEventoActual"]),
  },
  methods: {
    ...mapMutations(["setLoading"]),
    async getSliders() {
      const { data } = await API.getSliders({ evento_fk: this.idEventoActual });
      this.sliders = data.results.sort((a, b) => a.orden - b.orden);
      this.setLoading(false);
    },
    async getInfo(){
      const params = {
        "evento_fk" : this.idEventoActual,
        format:  "json"
      };
      const { data } = await API.getInfo(params);
      let items = data.results;
      for (let i in items){
        this.header = (items[i].codigo === 'RE01') ? items[i] : this.header;
        this.headerRegistro = (items[i].codigo === 'RE03') ? items[i] : this.headerRegistro;
        this.formaPago = (items[i].codigo === 'RE05') ? items[i] : this.formaPago;
        this.registroGeducar = (items[i].codigo === 'RE06') ? items[i] : this.registroGeducar;
        if(items[i].codigo === 'RE02'){
          this.tabla.push(items[i]);
        }
        if(items[i].codigo === 'RE04'){
          this.tutorial.push(items[i]);
        }
        if(items[i].codigo === 'RE07'){
          this.videos.push(items[i]);
        }
      }
    },
  },
}
</script>

<style scoped>
  .title::before{
    content: '';
    position: absolute;
    width: 3rem;
    top: -15px;
    border-radius: 20px;
    border: 2px solid var(--theme-color);
  }
</style>
